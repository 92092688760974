import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);
  return (
    
     <div style={{backgroundColor: 'white',width:'100%'}}>
  <main>
      <h1>PrimalKolor</h1>
      <h2>
       Artwork about rest and play without the work
      </h2>
      
      <p>
//       <a  className="works2" href="/">works 1</a>&nbsp;/&nbsp;
        <a  className="works2" href="https://twitter.com/primalkolor" style="font-weight:bold">twitter</a>&nbsp;/&nbsp;
        <a  className="works2" href="mailto:artworkREMOVETHISTEXTFROMEMAILADDRESS@primalkolor.com"  style="font-weight:bold">contact</a>
        
        </p>
      <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
      

<div className="row"> 
  <div className="column">
    <img alt="" src="https://barnabymenagefiles.s3-eu-west-1.amazonaws.com/images/gallery_bstyle/bath_inset_29by20.jpg" style={{width:"100%",height:"auto"}}/>
//     <img alt="" src="https://barnabymenagefiles.s3-eu-west-1.amazonaws.com/images/gallery_bstyle/party_inset_29by20.jpg" style={{width:"100%",height:"141px"}}/>
//     <img alt="" src="https://barnabymenagefiles.s3-eu-west-1.amazonaws.com/images/gallery_bstyle/bathtime_inset_29by20.jpg" style={{width:"100%",height:"141px"}}/>
   
 
  </div>
//   <div className="column">
//     <img alt="" src="https://barnabymenagefiles.s3-eu-west-1.amazonaws.com/images/gallery_bstyle/pool_inset_29by20.jpg" style={{width:"100%",height:"141px"}}/>
//     <img alt="" src="https://barnabymenagefiles.s3-eu-west-1.amazonaws.com/images/gallery_bstyle/camping_inset_29by20.jpg" style={{width:"100%",height:"141px"}}/>
//     <img alt="" src="https://barnabymenagefiles.s3-eu-west-1.amazonaws.com/images/gallery_bstyle/club_inset_29by20.jpg" style={{width:"100%",height:"141px"}}/>
 
//   </div>  
//   <div className="column">
//     <img alt="" src="https://barnabymenagefiles.s3-eu-west-1.amazonaws.com/images/gallery_bstyle/poolroom_inset_29by20.jpg" style={{width:"100%",height:"141px"}}/>
//     <img alt="" src="https://barnabymenagefiles.s3-eu-west-1.amazonaws.com/images/gallery_bstyle/tequila_inset_29by20.jpg" style={{width:"100%",height:"141px"}}/>
//     <img alt="" src="https://barnabymenagefiles.s3-eu-west-1.amazonaws.com/images/gallery_bstyle/djrocks1080height.png" style={{width:"100%",height:"141px"}}/>
  
//   </div>

</div>


</div>

    </main>
     <div class="site-meta" style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",
     padding:'20px',fontSize:'12px'
    }}>© 2020 PrimalKolor. All rights reserved.</div>
  
     </div>
   
  );
}

export default App;
